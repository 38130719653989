import React from 'react';
import { Link } from 'gatsby';
import './footer.scss';

const Footer = ({ siteTitle }) => (
  <div
    className="footer"
  >
    <div
      className="footer-wrapper"
    >
      <div className="address">
        <h3 style={{ margin: 0 }}>
          <Link
            to="/"
          >
            {siteTitle}
          </Link>
        </h3>
        <p>7108 Church Ave</p>
        <p>Suite 100</p>
        <p>Pittsburgh, PA 15202</p>
        <a rel="phone" href="tel:4125555555">(412) 533-9282</a>
      </div>
      <nav>
        <ul>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/getting-started">Getting Started</Link></li>
          <li><Link to="/contact">Contact Us</Link></li>
        </ul>
      </nav>
    </div>
  </div>
);

export default Footer;
