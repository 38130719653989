import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Hamburger from './hamburger';
import './header.scss'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false
    };
    this.handleHamburgerClick = this.handleHamburgerClick.bind(this);
  }

  handleHamburgerClick() {
    this.setState((prevState) => ({
      menuActive: !prevState.menuActive
    }));
  }

  render() {
    const {
      siteTitle,
      showIntro
    } = this.props;
    const {
      menuActive
    } = this.state;

    return (
      <div
        className={`header ${menuActive ? "header--active":""}`}
      >
        <div
          className="header-wrapper"
        >
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              className="header-logo"
            >
              <img src="/images/logo.png" alt="Relation Works Logo" />
            </Link>
          </h1>
          <Hamburger active={menuActive} onClick={this.handleHamburgerClick} />
          <nav>
            <ul>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/getting-started">Getting Started</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </nav>
          {showIntro &&
          <div className="header-intro">
            <h2>Supporting mental wellness on your journey to a higher self</h2>
          </div>
        }
        </div>
      </div>
    );
  }
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  showIntro: PropTypes.bool
}

export default Header;
