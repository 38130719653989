import React from 'react';
import PropTypes from 'prop-types';
import './hamburger.scss';

const Hamburger = ({active, onClick}) => (
  <a onClick={onClick} className={`hamburger ${active ? "active":""}`}>
    <span className="patty" />
  </a>
);

Hamburger.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default Hamburger;