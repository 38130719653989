import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header';
import Footer from './footer';
import './layout.scss'

const Layout = ({ children, showIntro }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            desc
            keywords
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />
	        <link rel="icon" type="image/png" href="/images/favicon-32x32.png" sizes="32x32" />
	        <link rel="icon" type="image/png" href="/images/favicon-16x16.png" sizes="16x16" />

	        <link rel="mask-icon" href="/images/safari-pinned-tab.svg" color="#ff4400" />
	        <meta name="theme-color" content="#fff" />
          <meta name="description" content={data.site.siteMetadata.desc} />
          <meta name="keywords" content={data.site.siteMetadata.keywords} />
	        <meta property="og:type" content="business.business" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:url" content="https://relationworkspgh.com/" />
          <meta property="og:description" content={data.site.siteMetadata.desc} />
          <meta property="og:image" content="/images/og-image.jpg" />
          <link href="https://fonts.googleapis.com/css?family=Poppins|Rubik" rel="stylesheet" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} showIntro={showIntro} />
        <div
          className="content"
        >
          {children}
        </div>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showIntro: PropTypes.bool
};

export default Layout
